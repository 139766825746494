import { toNumber, op_Addition, compare, op_Multiply, floor, op_Division, fromParts, pow } from "../.fable/fable-library.3.1.16/Decimal.js";
import { singleton } from "../.fable/fable-library.3.1.16/AsyncBuilder.js";
import { createBroadcast, chainRestTendermintApi, getLatestBlock, MsgType, getCoinAmountFromFloat, coin, msgExecuteContractCompatParams, msgExecuteContractCompat as msgExecuteContractCompat_1, createMsgBroadcastClient, getAddressTokenBalanceUi, chainGrpcBankApi } from "../../../../lib/Injective/Injective.fs.js";
import { getNetworkEndpoints } from "@injectivelabs/networks";
import { printf, interpolate, toText } from "../.fable/fable-library.3.1.16/String.js";
import { FSharpResult$2 } from "../.fable/fable-library.3.1.16/Choice.js";
import { toDecimal } from "../.fable/fable-library.3.1.16/BigInt.js";
import { some } from "../.fable/fable-library.3.1.16/Option.js";
import { toString } from "../.fable/fable-library.3.1.16/Types.js";
import { DepositMsg } from "../../../../lib/Domain/Types/InjectiveHolding.fs.js";
import { fromNumber } from "../.fable/fable-library.3.1.16/Long.js";

export function floorDecimal(targetDigit, value) {
    const multiplier = pow(fromParts(10, 0, 0, false, 0), targetDigit);
    return op_Division(floor(op_Multiply(value, multiplier)), multiplier);
}

export function checkBalanceOfUser(environment, network, holdingContract, address, qty, holding, depositToken) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => {
        const api = chainGrpcBankApi(getNetworkEndpoints(network).grpc, environment);
        return singleton.Bind(getAddressTokenBalanceUi(api, address, holding.Balance.Denom, depositToken.decimals), (_arg1) => {
            const userDepositTokenBalanceUi = _arg1;
            const isInj = depositToken.address === "inj";
            return singleton.Bind(getAddressTokenBalanceUi(api, address, toText(interpolate("factory/%P()/%P()", [holdingContract, holding.Id])), depositToken.decimals), (_arg2) => {
                let arg20, arg10, arg20_1, arg20_2;
                if ((isInj ? (compare(userDepositTokenBalanceUi, op_Addition(qty, fromParts(9, 0, 0, false, 3))) >= 0) : false) ? true : ((!isInj) ? (compare(userDepositTokenBalanceUi, qty) >= 0) : false)) {
                    const matchValue = holding.MaxTotalDeposit;
                    if (matchValue == null) {
                        return singleton.Return(new FSharpResult$2(0, void 0));
                    }
                    else {
                        const max = matchValue;
                        const multiplier = pow(fromParts(10, 0, 0, false, 0), depositToken.decimals);
                        const maxUi = op_Division(toDecimal(max), multiplier);
                        return (compare(op_Addition(_arg2, qty), maxUi) > 0) ? singleton.Return(new FSharpResult$2(1, (arg20 = depositToken.symbol, (arg10 = floorDecimal(2, maxUi), toText(printf("User can only deposit %M %s in total."))(arg10)(arg20))))) : singleton.Return(new FSharpResult$2(0, void 0));
                    }
                }
                else {
                    return (isInj ? (compare(op_Addition(qty, fromParts(9, 0, 0, false, 3)), userDepositTokenBalanceUi) > 0) : false) ? singleton.Return(new FSharpResult$2(1, (arg20_1 = depositToken.symbol, toText(printf("Insufficient Balance! Please try depositing maximum %M %s. Remember to keep some INJ for transaction fees"))(userDepositTokenBalanceUi)(arg20_1)))) : singleton.Return(new FSharpResult$2(1, (arg20_2 = depositToken.symbol, toText(printf("Insufficient Balance! Please try depositing maximum %M %s"))(userDepositTokenBalanceUi)(arg20_2))));
                }
            });
        });
    }), (_arg3) => {
        const ex = _arg3;
        console.log(some(ex));
        return singleton.Return(new FSharpResult$2(1, toString(ex)));
    }));
}

export function deposit(network, holdingContract, walletStrategy, address, qty, holding, depositToken) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => {
        const client = createMsgBroadcastClient(network, walletStrategy);
        const depositMsg = new DepositMsg(holding.Id);
        const msg = {
            deposit: depositMsg.Deposit,
        };
        const msgExecuteContractCompat = new MsgType(1, msgExecuteContractCompat_1(msgExecuteContractCompatParams([coin(holding.Balance.Denom, getCoinAmountFromFloat(toNumber(qty), depositToken.decimals))], address, holdingContract, msg)));
        return singleton.Bind(getLatestBlock(chainRestTendermintApi("https://sentry.lcd.injective.network:443")), (_arg1) => singleton.Bind(createBroadcast(client, msgExecuteContractCompat, address), (_arg2) => singleton.Return(new FSharpResult$2(0, [_arg2, fromNumber(toNumber(qty) * Math.pow(10, depositToken.decimals), false)]))));
    }), (_arg3) => singleton.Return(new FSharpResult$2(1, toString(_arg3)))));
}

